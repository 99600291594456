/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { Container, CircularProgress } from '@material-ui/core'
import SEO from 'components/Seo'
import AppHeading from 'components/AppHeading'
import { useTranslation } from 'react-i18next'
import { useReference } from 'store/Reference'
import { useAnalysis } from 'store/Analysis'
import ExhibitionList from 'components/widgets/Exhibition/ExhibitionList'
import ExhibitionFilter from 'components/widgets/Exhibition/ExhibitionFilter'
import { pageView } from 'utils/tracker'
import { fetchApiAnalysis } from 'utils/apis'
import { TRACKING } from 'config/constants/common'
import { TYPE_OF_LIST } from 'config/constants/panel'
import './index.scss'

export default function Profile({ data }) {
  const { t } = useTranslation('mylist')
  const { postgres = {} } = data || {}
  const { allEcmsEventTracks = {} } = postgres || {}
  const { nodes: listTracks = [] } = allEcmsEventTracks || {}

  const { getMyListFavorite } = useReference()
  const { getListPanelsViews } = useAnalysis()
  const [filter, setFilter] = useState({})
  const [relevan, setRelevan] = useState('')
  const [expanded, setExpanded] = useState(false)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    // Fetch analysis apis
    async function fetchApis() {
      await fetchApiAnalysis(getListPanelsViews)
    }

    const getMyList = async () => {
      try {
        pageView(trackingEventSource)
        await getMyListFavorite()
        setLoading(false)
      } catch (e) {
        setLoading(false)
        console.log(e)
      }
    }

    fetchApis()
    getMyList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const trackingEventSource = TRACKING.SOURCE.MY_LIST
  const appTitle = t('lbl_title_en')

  return (
    <div id="profile-view" className={'Layout-children'}>
      <SEO title={appTitle} />
      <AppHeading title={appTitle} />

      <div className="profile-content">
        <div className="profile-main">
          <ExhibitionFilter
            filter={filter}
            setFilter={setFilter}
            relevan={relevan}
            setRelevan={setRelevan}
            expanded={expanded}
            setExpanded={setExpanded}
            selectedRadio={TYPE_OF_LIST.MY_LIST}
            haveRadio
            navRadio
          />
          <Container style={{ paddingTop: 28 }}>
            {isLoading ? (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </div>
            ) : (
              <ExhibitionList
                filter={filter}
                isMyList
                listTracks={listTracks}
                trackingEventSource={trackingEventSource}
              />
            )}
          </Container>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  query($eventId: Int!) {
    postgres {
      allEcmsEventTracks(condition: { eventId: $eventId }) {
        nodes {
          ecmsEventTopicsByTrackId {
            nodes {
              ecmsEventPanelsByTopicId {
                nodes {
                  languageCode
                  panelCode
                  panelDescription
                  panelId
                  panelName
                  shortCode
                  sequenceNo
                  ecmsPanelTagsByPanelId {
                    nodes {
                      ecmsTagListByTagListId {
                        tagGroupId
                        tagListId
                      }
                    }
                  }
                  ecmsEventFilesByPanelId {
                    nodes {
                      id
                      eventId
                      fileCategory
                      fileCode
                      fileDescription
                      fileId
                      fileSize
                      fileType
                      fileName
                      keyCode
                      url
                    }
                  }
                }
              }
              topicName
              sequenceNo
            }
          }
          trackName
          sequenceNo
        }
      }
    }
  }
`
